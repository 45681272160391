<template>
   <el-select v-model="receiveMode" @change="reqSetWebComAdminSub" style="width: 180px" size="small" collapse-tags multiple placeholder="请选择推送方式">
        <el-option
        v-for="item of $dictionary('receiveMode')"
        :key="item.value"
        :label="item.label"
        :value="item.value">
        </el-option>
    </el-select>
</template>

<script>

  export default {
     data(){
        return {
          receiveMode: [],
          flags: true,
        }  
      
     },
     created(){
        this.reqComAdminSubDetail()
     },
     methods:{
        async reqComAdminSubDetail(){
           const res = await this.$api.ComAdminSubDetail()
           if(res)this.receiveMode = this.handler(res.receiveMode)
        
        },
        async reqSetWebComAdminSub(){
           let receiveMode = 0
           this.receiveMode.map((val) => (receiveMode += val));
           const res = await this.$api.SetWebComAdminSub({ receiveMode })
        },
        handler(val) {
          if (val !== 0 && !val) {
            val = null;
          } else {
            val = parseInt(val);
          }
          if (this.flags) {
            let arr = [];
            if (val != null) {
              for (var i = 0; i < 32; i++) {
                let v = 0x01 << i;
                if (val & v) arr.push(v);
              }
            }
            return arr;
          } else {
            return val;
          }
        },
     }
  }
</script>

<style lang="scss" scoped>

</style>