<template>
  <div class="windowInfoMainBox" ref="winInfo">
      <div class="top_box">
         <div>
           <span class="iconfont icon-title baseColor"></span>
           <span>{{ baseInfo ? baseInfo.fenceName : '围栏' }}</span>
        
         </div>
         <span class="el-icon-close cp" @click="colseClick"></span>
     </div>
     <div class="content_box" v-if="baseInfo">
      <div class="grid-content">
              <span>所属基地：</span>
              <span>{{ baseInfo.baseInfoName }}</span>
         </div>
        <div class="grid-content">
              <span>围栏编号：</span>
              <span>{{ baseInfo.fenceNum }}</span>
         </div>
         <div class="grid-content">
              <span>占地面积：</span>
              <span>{{ baseInfo.coverArea ? baseInfo.coverArea + '亩' : '无' }}</span>
         </div>
         
         <div class="grid-content" v-for="item in almInfos" :key="item.name">
              <span>{{ item.name }}：</span>
              <span>{{ item.number || 0 }}头 </span>
              <span class="standbyColor" style="margin-left: 5px;" v-if="item.abnormityNumber"> {{ item.abnormityNumber }}头异常</span>
         </div>
         
         <div class="grid-content">
              <span>围栏状态：</span>
              <hn-format-dic v-model="baseInfo.fenceStatus" type="fenceStatus"></hn-format-dic>
         </div>
     </div>
  </div>

</template>

<script>

  export default {
  
      props:{
        baseInfo: Object
      },
      data(){
          return {
      
          }
      },
      mounted(){
        setTimeout(() => { console.log(this.baseInfo) },1000)
         
      },
      computed:{
        almInfos(){
          return this.baseInfo ?.almInfos || []
        }
      },
      methods:{
          colseClick(){
              this.onClose()
          },

      }
    

  }
</script>

<style lang="scss" scoped>
.windowInfoMainBox{
  padding: 20px 10px 0px 10px;
  background-color: rgba(#15232D,0.5);
  border-radius: 10px;
  font-size: 14px;
  width: 240px;
  position: relative;
  .top_box{
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > div{
          display: flex;
          align-items: center;
          font-size: 16px;
          & > span:nth-child(1){
              font-size: 24px;
              margin-right: 2px;
          }
          & > span:nth-child(3){
              margin-left: 6px;
          }
      }
      & > span {
          font-size: 18px;
      }
  }
  .content_box{
      padding: 20px 5px 20px 20px;
      box-sizing: border-box;
      .grid-content:not(:first-child){
        margin-top: 8px;
      }
  }

}
.grid-content{
    display: flex;
}
.windowInfoMainBox::after{
  content: ' ';
  display: block;
  border-style: solid;
  border-color: transparent;
  border-width: 10px 10px 10px 0px;
  border-right-color: rgba(#15232D,0.5);
  position: absolute;
  left: -10px;
  top: 50px;
}

</style>