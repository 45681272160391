<template>
  <div class="windowInfoMainBox" ref="winInfo">
    <div class="top_box">
      <div>
        <span class="iconfont icon-title baseColor"></span>
        <span>{{ animalName }}</span>
      </div>
      <span class="el-icon-close cp" @click="colseClick"></span>
    </div>
    <div class="content_box" v-if="animeInfo">
      <el-row :gutter="20">
        <el-col :span="14"
          ><div class="grid-content">
            <span>普通耳标：</span>
            <span>{{ animeInfo.earCode }}</span>
          </div></el-col
        >
        <el-col :span="10"
          ><div class="grid-content">
            <span>今日步数：</span>
            <span>{{ animeInfo.todayWalk || "无" }}</span>
          </div></el-col
        >
      </el-row>
      <el-row :gutter="20" style="margin-top: 8px">
        <el-col :span="14"
          ><div class="grid-content">
            <span>项圈编号：</span>
            <span>{{ animeInfo.necklaceCode }}</span>
          </div></el-col
        >
        <el-col :span="10"
          ><div class="grid-content">
            <span>毛色：</span>
            <span>{{ animeInfo.coatColor || "无" }}</span>
          </div></el-col
        >
      </el-row>
      <el-row :gutter="20" style="margin-top: 8px">
        <el-col :span="14"
          ><div class="grid-content">
            <span>智能耳标：</span>
            <span>{{ animeInfo.iotDevCode }}</span>
          </div></el-col
        >
        <el-col :span="10"
          ><div class="grid-content">
            <span>性别：</span>
            <span>{{ animeInfo.sex == 1 ? "雄性" : "雌性" }}</span>
          </div></el-col
        >
      </el-row>
      <el-row :gutter="20" style="margin-top: 8px">
        <el-col :span="14"
          ><div class="grid-content">
            <span>体温：</span>
            <span>{{ animeInfo.temperature ? animeInfo.temperature + "℃" : "无" }}</span>
          </div></el-col
        >
        <el-col :span="10"
          ><div class="grid-content">
            <span>状态：</span>
            <!-- <span>{{ animeStatus }}</span> -->
            <hn-format-dic v-model="animeInfo.status" type="animalType"></hn-format-dic></div
        ></el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top: 8px">
        <el-col :span="14"
          ><div class="grid-content">
            <span>体重：</span>
            <span>{{ animeInfo.weight ? animeInfo.weight + "kg" : "无" }}</span>
          </div></el-col
        >
        <el-col :span="10"
          ><div class="grid-content">
            <span>纬度：{{ lat }}</span>
            <span></span></div
        ></el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top: 8px">
        <el-col :span="14"
          ><div class="grid-content">
            <span>体长：</span>
            <span>{{ animeInfo.length ? animeInfo.length + "cm" : "无" }}</span>
          </div></el-col
        >
        <el-col :span="10"
          ><div class="grid-content">
            <span>经度：{{ lng }}</span>
            <span></span></div
        ></el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top: 8px">
        <el-col :span="14"
          ><div class="grid-content">
            <span>身高：</span>
            <span>{{ animeInfo.height ? animeInfo.height + "cm" : "无" }}</span>
          </div></el-col
        >
        <el-col :span="10">
          <div class="but cp" @click="toSimp">运动轨迹</div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      animeId: String,
      pointInfo: Object,
    },
    watch: {
      animeId: {
        handler(val) {
          if (val) this.getTraTraAnimalInfoDetail();
        },
      },
    },
    data() {
      return {
        animeInfo: null,
      };
    },
    computed: {
      lngLat() {
        return this.pointInfo?.currPosition ? this.pointInfo.currPosition.split(",") : "";
      },
      lng() {
        if (this.lngLat) {
          let str = this.lngLat[0];
          let length = str.split(".")[1]?.length;
          return length > 6 ? Number(str).toFixed(6) : str;
        }
        return "";
      },
      lat() {
        if (this.lngLat) {
          let str = this.lngLat[1];
          let length = str.split(".")[1]?.length;
          return length > 6 ? Number(str).toFixed(6) : str;
        }
        return "";
      },
      animalName(){
         return this.animeInfo ?.animalName || '动物详情'
      }
      // animeStatus(){
      //     let status = this.animeInfo ?.status
      //     if(!status) return '无'
      //     const info = {
      //         '1': '正常',
      //         '2':'生病',
      //         '3':'已出栏',
      //         '4':'死亡'
      //     }
      //     return info[status]
      // }
    },
    methods: {
      colseClick() {
        this.onClose();
      },
      // 获取动物信息
      async getTraTraAnimalInfoDetail() {
        const res = await this.$api.TraAnimalInfoDetail({ animalId: this.animeId });
        this.animeInfo = res;
      },
      async toSimp() {
        const res = await this.$api.TraFenceAnimalLocus({ id: this.animeId });

        if (res && res.length) {
          this.onParam({ id: this.animeId, path: res });
          this.onClose();
        } else {
          this.$msg.info("暂无动物轨迹");
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .windowInfoMainBox {
    padding: 20px 10px 0px 10px;
    background-color: rgba(#15232d, 0.5);
    border-radius: 10px;
    font-size: 14px;
    width: 420px;
    position: relative;
    .top_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > div {
        display: flex;
        align-items: center;
        font-size: 16px;
        & > span:nth-child(1) {
          font-size: 24px;
          margin-right: 2px;
        }
        & > span:nth-child(3) {
          margin-left: 6px;
        }
      }
      & > span {
        font-size: 18px;
      }
    }
    .content_box {
      padding: 20px 5px 20px 20px;
      box-sizing: border-box;
    }
    .but {
      width: 92px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background: rgba(242, 245, 250, 0.1);
      border: 1px solid #f2f5fa;
      border-radius: 10px;
    }
    .grid-content {
      display: flex;
    }
  }
  .windowInfoMainBox::after {
    content: " ";
    display: block;
    border-style: solid;
    border-color: transparent;
    border-width: 10px 10px 10px 0px;
    border-right-color: rgba(#15232d, 0.5);
    position: absolute;
    left: -10px;
    top: 50px;
  }
</style>
